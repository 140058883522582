/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import '@ctrl/ngx-emoji-mart/picker';

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Outfit', "Helvetica Neue", sans-serif;

  .mat-typography,
  h2,
  h1 {
    font-family: 'Outfit' !important;
  }
}

.mat-mdc-card {
  border-radius: var(--mdc-elevated-card-container-shape, var(--mdc-shape-medium, 16px)) !important;
}


@include mat.core();

// ****** START:  LAYOUT STYLES ******
.top-spacer {
  margin-top: 1rem;
}

.left-spacer {
  margin-left: 1rem;
}

.extra-top-spacer {
  margin-top: 2rem;
}

.max-width {
  display: flex;
  align-items: start;
  justify-content: center;
  width: 1200px;
  flex-wrap: wrap;
  margin-top: 2rem;

  .first-column {
    width: 640px;
    margin-left: 40px;
    margin-right: 20px;
  }

  .second-column {
    width: 450px;
  }

  .left-spacer {
    margin-left: 40px;
  }
}

.no-top-margin {
  margin-top: unset;
}


.top-center-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

$primary-font: "Outfit";

.r-icon {
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}

.button {
  width: 130px !important;
  height: 35px !important;
  padding: 0px 8px !important;
  border: 0 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  outline: none !important;
}

.primary-button {
  @extend .button;
  background-color: #5d5d5b !important;
  color: #ffffff !important;
}

.secondary-button {
  @extend .button;

  border: 1px solid #5d5d5b !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #5d5d5b !important;
  font-size: 12px !important;
}

.danger-button {
  @extend .button;
  background-color: #ff2d55 !important;
  color: #ffffff !important;

}

.disabled-button {
  @extend .button;
  border: 1px solid #e5e5e5 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #e5e5e5 !important;
}

h4 {
  color: #030303 !important;
  font-size: 14px !important;
  font-family: $primary-font !important;
  line-height: 18px !important;
}

@media screen and (max-width: 1460px) {
  .page-container {
    margin-left: 50px;
  }
}

.page-container {
  display: flex;
  width: 1080px;
  justify-content: flex-start;
  flex-wrap: wrap;

  .page-content {
    width: 630px;
    margin-right: 40px;
  }

  .right-sidebar {
    width: 410px;
  }
}

.header-container {
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.text {
  font-size: 12px;
}

span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
a,
p,
li,
th,
td,
input,
textarea,
mat-option,
tr mat-card,
mat-card-title,
mat-card-subtitle {
  font-family: $primary-font !important;
}

mat-option,
mat-optgroup {
  font-size: 14px !important;
}

mat-dialog-actions {
  padding: 20px 24px 20px 24px !important;
}

.mr-1 {
  margin-right: 1rem;
}

.header-margin-top {
  margin-top: -3.5rem !important;
}

a,
a:visited {
  color: #306c84 !important;
  text-decoration: none !important;
}

a:hover {
  color: #670606 !important;
  text-decoration: underline !important;
}

a:active {
  color: #1a1a1a !important;
}

* {
  // @TODO: Revisit the following restriction below in the future
  font-weight: 400 !important;
}

.custom-scrollbar {
  overflow-y: auto;
  /* Enables vertical scrollbar */
  max-height: 550px;
  /* Sets a maximum height for the content area */
  /* You can adjust the max-height value based on your requirements */
}

/* Optional: Customize the scrollbar appearance */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  opacity: 1;
  /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
  /* Color of the scrollbar handle */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 4px;
  /* Color of the scrollbar handle on hover */
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #306c84 !important;
  --mdc-linear-progress-track-color: #306c84 !important;
}

.hidden-component {
  display: none;
}

.app-emoji {
  font-size: 28px;
  margin-top: 5px;
  margin-right: 8px;
}

.app-emoji-32 {
  font-size: 32px !important;
  height: 40px !important;
  width: 40px !important;
}

